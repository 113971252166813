















































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Region } from "@/models/Region";
import {
  REGION_REQUEST,
  REGION_UPDATE,
  REGION_CREATE,
  REGION_DELETE,
  REGION_UPDATED,
  REGION_LOADED,
} from "@/store/actions/main";

@Component({})
export default class Regions extends Vue {
  private regions: any[] = [];
  private regionCreateForm: any = {};
  private regionsHeaders = [
    {
      text: "Иконка",
      value: "icon_path",
      align: "start",
      sortable: false,
      width: "15%",
    },
    {
      text: "ID",
      value: "id",
      align: "start",
      width: "5%",
    },
    {
      text: "Области",
      value: "districts",
      align: "center",
      sortable: false,
      width: "10%",
    },
    {
      text: "Название",
      value: "name",
      align: "start",
      width: "45%",
    },
    {
      text: "Карта",
      value: "map",
      align: "center",
      sortable: false,
      width: "10%",
    },
    {
      text: "Действия",
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  private loading: boolean = false;
  //Image preview
  private fullscreen = false;
  private imageView = false;
  private imgUrl = "";
  private search = "";
  private itemToDelete: Region = new Region();
  private deleteDialog: boolean = false;
  private deleteSnackbar: boolean = false;
  private deleteDisabled: boolean = false;
  //options
  private tablePage = 1;
  private tablePageCount = 0;
  private itemsPerPage = 15;

  //Initialise methods
  private initIfReady() {
    //return true if started to init self.
    let needReload = [] as boolean[];
    needReload.push(
      this.needReload(
        this.$store.getters.REGION_TABLE,
        this.$store.getters.REGION_LOADED
      )
    );
    for (let i = 0; i < needReload.length; i++) {
      if (needReload[i] == true) {
        return false;
      }
    }
    console.log("tabl", this.$store.getters.REGION_TABLE);
    console.log("loaded", this.$store.getters.REGION_LOADED);
    console.log("TRUE INIT SELF");
    this.initSelf();
    return true;
  }
  private needReload(table, updated) {
    /*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
    if (table == null || !updated) return true;
    return false;
  }
  private tryToInitSelf() {
    this.loading = true;
    //const data = this.getParams();
    if (this.initIfReady()) return;
    if (
      this.needReload(
        this.$store.getters.REGION_TABLE,
        this.$store.getters.REGION_LOADED
      )
    ) {
      this.$store.dispatch(REGION_REQUEST).then(() => {
        this.initIfReady();
      });
    }
  }
  private initSelf() {
    console.log("INIT SELF");
    this.regions = this.$store.getters.REGION_TABLE.data;
    this.loading = false;
    console.log("loading");
  }
  //Methods
  private isDeleting(id) {
    if (this.itemToDelete.id == id) return true;
    return false;
  }
  private cleanDeleting() {
    this.itemToDelete = new Region();
  }
  private toCreate() {
    //localStorage.removeItem("widgetToEdit");
    this.$router.push({ name: "RegionsCreate" });
  }
  private toDistricts(item){
    const parsed = JSON.stringify(item);
    localStorage.setItem("currentRegion", parsed);
    localStorage.setItem("regionTitle", item.title);
    this.$router.push({ name: "Districts", params: { id: item.id } });
  }
  private toMap(item) {
    this.$router.push({ name: "MapsUpdate", params: { id: item.id } });
  }
  private toEdit(item) {
    console.log("item: ", item);
    const parsed = JSON.stringify(item);
    localStorage.setItem("regionToEdit", parsed);
    this.$router.push({ name: "RegionsUpdate", params: { id: item.id } });
  }
  private deleteItem(item: Region, confirmation: boolean) {
    this.itemToDelete = item;
    if (confirmation) {
      this.deleteDialog = true;
    } else {
      console.log(item);
      console.log("Delete!");
      this.$store.dispatch(REGION_DELETE, item.id).then(() => {
        this.tryToInitSelf();
        this.deleteSnackbar = true;
        this.itemToDelete = new Region();
        this.deleteDisabled = false;
      });
      this.deleteDialog = false;
    }
  }
  private getSrc(address) {
    return process.env.VUE_APP_API_ENDPOINT + "/" + address;
  }
  private setImgUrl(url) {
    this.imageView = true;
    this.imgUrl = url;
  }
  //Hooks
  private mounted() {
    this.tryToInitSelf();
  }
  private nameSearch(value, search, item): boolean {
    return (
      value != null &&
      search != null &&
      typeof value === "string" &&
      value
        .toString()
        .toLocaleLowerCase()
        .indexOf(search.toString().toLocaleLowerCase()) !== -1
    );
  }
}
