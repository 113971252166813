class Region {
	constructor(
		public id?: number,
		public title?: string,
		public name?: string,
		public icon_file?: any,
		public icon_path?: string,
	) {
		this.id = id;
		this.title = title;
		this.name = name;
		this.icon_file = icon_file;
		this.icon_path = icon_path;
	}
}
class District {
	constructor(
		public id?: number,
		public title?: string,
		public name?: string,
	) {
		this.title = title;
		this.name = name;
	}
}
export {
	Region,
	District,
}